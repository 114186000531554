<template>
  <WeContainer card="" v-if="ready">
    <div class="row align-items-stretch mb-3">
      <!-- Left Card -->
      <div class="col-12 mb-3 col-lg-8 mb-lg-0">
        <WeCard class="mb-3 h-100">
          <h5><i class="fas fa-shopping-basket"></i> {{ getTitle }}</h5>
          <hr />

          <!-- Customer -->
          <div class="form-group">
            <WeSearchInput
              v-if="orderCustomer && !orderCustomer.id"
              label="Müşteri"
              prepend="<i class='fas fa-user'></i>"
              result-prop="full_name"
              name="customer"
              v-bind:required="true"
              v-bind:error="$v.orderCustomer.$error"
              v-on:on-select="selectCustomerAndAddress($event)"
              v-bind:placeholder="$t('makeSearch')"
            />

            <!-- Selected Customer -->
            <div v-if="orderCustomer && orderCustomer.id">
              <div class="card shadow-sm">
                <div class="card-body">
                  <div class="media align-items-center">
                    <!-- Customer Avatar -->
                    <img
                      class="rounded-circle"
                      src="https://picsum.photos/42/42"
                      alt=""
                    />
                    <!-- ./Customer Avatar -->

                    <!-- Customer Info -->
                    <div class="media-body ml-3">
                      <span class="font-weight-bold d-block">{{
                        orderCustomer.full_name
                      }}</span>
                      <span class="d-block text-muted">
                        {{ orderCustomer.email }}
                      </span>
                    </div>
                    <!-- ./Customer Info -->

                    <div class="ml-auto">
                      <span
                        class="btn alert-danger"
                        v-on:click.prevent="removeCustomerAndAddress()"
                      >
                        <i class="fas fa-trash"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- ./Selected Customer -->
          </div>
          <!-- ./Customer -->
          <div class="form-group">
            <v-select
              class="custom-v-select"
              label="name"
              v-model="type"
              placeholder="Sipariş Tipi Seç"
              v-bind:options="typeList"
              v-show="orderCustomer.id"
              v-bind:clearable="false"
            />
          </div>
          <div v-if="type && type.id == 2">
            <label>Tahsilat Tutarı</label>
            <div class="col-12 mb-3 p-0">
              <WePriceInput
                class="mb-0"
                placeholder="0,00"
                v-model="withOutSaleOrder.price"
                ref="endPrice"
              />
              <label class="mt-2">{{ $t("description") }}</label>
              <textarea
                class="form-control"
                v-model="withOutSaleOrder.description"
              >
              </textarea>
            </div>
          </div>
          <div v-if="type && type.id == 1">
            <!-- Shipment Address -->
            <div
              class="card mb-3"
              v-bind:class="{ 'border-danger': $v.orderShipment.$error }"
            >
              <!-- Shipment Address Collapse -->
              <button
                class="col-12 btn btn-light border-0 rounded-top"
                type="button"
                data-toggle="collapse"
                data-target="#shipmentAddress"
                aria-expanded="false"
                aria-controls="shipmentAddress"
              >
                <div class="row align-items-center">
                  <div class="col text-left py-2">
                    Teslimat Adresi
                    <span
                      class="badge badge-secondary"
                      v-if="
                        orderCustomer.contacts && orderCustomer.contacts.length
                      "
                      >{{ orderCustomer.contacts.length }} Kayıt</span
                    >
                  </div>

                  <span class="col-auto">
                    <i class="fas fa-chevron-down"></i>
                  </span>
                </div>
              </button>
              <div class="collapse" id="shipmentAddress">
                <div class="px-2 mt-3">
                  <div
                    v-if="
                      orderCustomer &&
                      orderCustomer.contacts &&
                      orderCustomer.contacts.length
                    "
                  >
                    <button
                      class="btn btn-outline-secondary font-weight-bold mr-2"
                      v-for="(contact, index) in orderCustomer.contacts"
                      v-bind:key="contact.id"
                      v-on:click="selectShipment(index)"
                    >
                      <i class="fas fa-address-card"></i> {{ contact.name }}
                    </button>
                  </div>
                  <i v-else>Teslimat Adresi Mevcut Değil</i>
                </div>
                <hr class="mb-0" />
              </div>
              <!-- ./Shipment Address Collapse -->
              <div class="card-body">
                <Address name="shipment" v-model="orderShipment" class="mb-3" />
              </div>
            </div>
            <!-- ./Shipment Address -->

            <!-- Invoice Address -->
            <div
              class="card"
              v-bind:class="{ 'border-danger': $v.orderInvoice.$error }"
            >
              <!-- Invoice Address Collapse -->
              <button
                class="col-12 btn btn-light border-0 rounded-top"
                type="button"
                data-toggle="collapse"
                data-target="#invoiceAddress"
                aria-expanded="false"
                aria-controls="invoiceAddress"
              >
                <div class="row align-items-center">
                  <div class="col text-left py-2">
                    Fatura Adresi
                    <span
                      class="badge badge-secondary"
                      v-if="
                        orderCustomer.contacts && orderCustomer.contacts.length
                      "
                      >{{ orderCustomer.contacts.length }} Kayıt</span
                    >
                  </div>
                  <span class="col-auto">
                    <i class="fas fa-chevron-down"></i>
                  </span>
                </div>
              </button>
              <div class="collapse" id="invoiceAddress">
                <div class="px-2 mt-3">
                  <div
                    v-if="
                      orderCustomer &&
                      orderCustomer.contacts &&
                      orderCustomer.contacts.length
                    "
                  >
                    <button
                      class="btn btn-outline-secondary font-weight-bold mr-2"
                      v-for="(contact, index) in orderCustomer.contacts"
                      v-bind:key="contact.id"
                      v-on:click="selectInvoice(index)"
                    >
                      <i class="fas fa-address-card"></i> {{ contact.name }}
                    </button>
                  </div>
                  <i v-else>Fatura Adresi Mevcut Değil</i>
                </div>
                <hr class="mb-0" />
              </div>
              <!-- ./Invoice Address Collapse -->
              <div class="card-body">
                <Address
                  name="shipment"
                  v-model="orderInvoice"
                  v-bind:error="$v.orderInvoice.$error"
                />
                <div class="text-md-right">
                  <div class="form-group form-check mb-0">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="same-address"
                      v-model="order.new.address.same"
                      v-on:change="updateInvoiceAddress"
                    />
                    <label class="form-check-label font-14" for="same-address"
                      >Teslimat Adresi ile aynı</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- ./Invoice Address -->

            <div
              class="text-left"
              v-if="Object.keys(orderCustomer).length && orderCustomer.id !== 1"
            >
              <span v-on:click="show.modal = true" class="btn text-primary"
                ><i class="fas fa-plus"></i> Yeni Adres Ekle</span
              >
            </div>
               <div class="col-12 mb-3 p-0">
            <label class="mt-2">{{ $t("description") }}</label>
            <textarea class="form-control" v-model="description"> </textarea>
          </div>
          </div>
       
        </WeCard>
      </div>
      <!-- ./Left Card -->

      <!-- Right Card -->
      <div class="col-12 mb-3 col-lg-4 mb-lg-0">
        <WeCard class="h-100">
          <div v-if="type && type.id == 2">
            <div class="mb-3">
              <div class="row">
                <div class="col">
                  <label for="payment_type" class="custom-label"
                    >Ödeme Yöntemi</label
                  >
                </div>
              </div>
              <select
                id="payment_type"
                name="payment_type"
                class="custom-select"
                disabled
              >
                <option>Kredi Kartı</option>
              </select>
              <!-- Send Buy Link -->
              <div class="text-right mt-2">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="send-link"
                    checked
                  />
                  <label class="custom-control-label font-14" for="send-link"
                    >Link Gönder</label
                  >
                </div>
              </div>
              <div class="col-12">
                <!-- ./Send Buy Link -->
                <WeSubmitButton
                  parent-class="mt-5 text-center"
                  v-bind:submit-status="submit"
                  v-bind:btnText="'Oluştur ve Gönder'"
                  v-on:submit="saveWithOutOrder"
                />
                <div class="row mt-5" style="border: 1px solid #eaeaea; padding: 10px 0px; background: #f8f9fa;" v-if="withoutUrl">
                <h5 @click="copyWithoutLink()" style=" padding: 0px 15px;">Oluşturulan Ödeme Linki <i class="fas fa-copy"></i></h5>
                <div class="col-12">
                    <a target="_blank" :href="withoutUrl" style="padding: 0;font-size: 14px;">{{withoutUrl}}</a>
                </div>
               </div>
              </div>
            </div>
          </div>
          <!-- Payment Type -->
          <div v-if="type && type.id == 1">
            <div class="mb-3">
              <div class="row">
                <div class="col">
                  <label
                    for="payment_type"
                    class="custom-label"
                    v-bind:class="{ 'text-danger': $v.defaultPayment.$error }"
                    >Ödeme Yöntemi</label
                  >
                </div>
                <div class="col-auto" v-if="$v.defaultPayment.$error">
                  <small class="text-danger"><i>Zorunlu Alan</i></small>
                </div>
              </div>
              <select
                id="payment_type"
                name="payment_type"
                v-model="defaultPayment"
                class="custom-select"
                v-bind:class="{ 'border-danger': $v.defaultPayment.$error }"
              >
                <option
                  v-for="(type, i) in order.paymentTypes"
                  v-bind:key="type.id"
                  v-bind:value="i"
                >
                  {{ type.name }}
                </option>
              </select>
              <!-- Send Buy Link -->
              <div class="text-right mt-2" v-show="order.new.type.payment == 2">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="send-link"
                    v-model="sendLink"
                  checked>
                  <label class="custom-control-label font-14" for="send-link"
                    >Link Gönder</label
                  >
                </div>
              </div>
              <!-- ./Send Buy Link -->
            </div>
            <!-- Payment Type -->

            <!-- Order Type -->
            <div class="mb-3">
              <div class="row">
                <div class="col">
                  <label
                    for="order_type"
                    class="custom-label"
                    v-bind:class="{ 'text-danger': $v.orderStatus.$error }"
                    >Sipariş Durumu</label
                  >
                </div>
                <div class="col-auto" v-if="$v.orderStatus.$error">
                  <small class="text-danger"><i>Zorunlu Alan</i></small>
                </div>
              </div>
              <select
                name="order_type"
                id="order_type"
                v-model="order.new.type.order"
                class="custom-select"
                v-bind:class="{ 'border-danger': $v.orderStatus.$error }"
              >
                <option
                  v-for="type in order.orderTypes"
                  v-bind:key="type.id"
                  v-bind:value="type.id"
                >
                  {{ type.name }}
                </option>
              </select>
            </div>
            <!-- Order Type -->

            <!-- Cargo Company -->
            <div class="mb-3">
              <div class="row">
                <div class="col">
                  <label
                    for="cargo-company"
                    class="custom-label"
                    v-bind:class="{ 'text-danger': $v.cargoCompany.$error }"
                    >Kargo Firması</label
                  >
                </div>
                <div class="col-auto" v-if="$v.cargoCompany.$error">
                  <small class="text-danger"><i>Zorunlu Alan</i></small>
                </div>
              </div>
              <select
                name="cargo-company"
                id="cargo-company"
                v-model="cargoCompany"
                class="custom-select"
                v-bind:class="{ 'border-danger': $v.cargoCompany.$error }"
              >
                <option
                  v-for="cargo in shared.cargoCompanies.firmList"
                  v-bind:key="cargo.id"
                  v-bind:value="cargo.id"
                >
                  {{ cargo.name }}
                </option>
              </select>
            </div>
            <!-- Cargo Company -->

            <!-- Gift -->
            <div class="row align-items-top justify-content-between">
              <div class="col">
                <span class="custom-label">Hediye Paketi</span>
              </div>
              <div class="col-6">
                <!-- Gift Switcher -->
                <div class="float-right mb-2">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="gift"
                      v-model="order.new.gift.status"
                    />
                    <label class="custom-control-label" for="gift"></label>
                  </div>
                </div>
                <!-- ./Gift Switcher -->
              </div>
            </div>
            <div v-if="order.new.gift.status">
              <textarea
                id="gift-note"
                name="gift_note"
                cols="30"
                rows="10"
                class="form-control address"
                placeholder="Hediye Notu"
                v-model="order.new.gift.text"
              ></textarea>
            </div>
            <!-- Gift -->
          </div>
        </WeCard>
      </div>
      <!-- ./Right Card -->
    </div>

    <!-- Products -->
    <WeProduct
      title="Ürünler"
      name="product"
      icon="fas fa-cube"
      v-bind:columns="productColumns"
      v-bind:actions="productActions"
      v-bind:searchable="true"
      v-bind:quantity="true"
      v-bind:show-total="true"
      v-bind:show-vat="true"
      v-bind:body-overflow="false"
      searchText="Ürün Ara"
      v-model="orderProducts"
      v-on:total-changed="getTotalPrice"
      v-bind:class="{ 'border-danger': $v.orderProducts.$error }"
      v-if="type && type.id == 1"
    />
    <!-- Products -->

    <!-- Submit -->
    <WeSubmitButton
      parent-class="mt-3 text-center"
      v-bind:submit-status="submit"
      v-bind:update="$route.params.id"
      v-bind:permission="checkPermission"
      v-on:submit="saveForm"
      v-if="type && type.id == 1"
    />
    <!-- Submit -->

    <WeModal
      v-if="show.modal"
      v-on:close="show.modal = false"
      title="Yeni Adres Ekle"
    >
      <div slot="body">
        <NewAddress v-on:on-save="onAddressSave" />
      </div>
    </WeModal>
  </WeContainer>
  <WeLoading v-else />
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import NewAddress from "./views/NewAddress/Index";
import Address from "./views/Address/Index";

export default {
  name: "Detail",
  data() {
    return {
      withoutUrl: null,
      type: null,
      delay: 500,
      length: 3,
      show: {
        modal: false,
      },
      ready: false,
      submit: false,
      withOutSaleOrder: {
        price: helper.setPrice(0),
        description: null,
      },
      productActions: [
        {
          icon: "fas fa-trash",
          class: "btn-outline-danger ml-2",
          action: "remove",
        },
      ],
      typeList: [
        { id: 1, name: "Normal Sipariş" },
        { id: 2, name: "Satışsız Tahsilat" },
      ],
      productColumns: [
        { name: "name", th: "Ürün Adı", type: "string" },
        { name: "sku_no", th: "Stok Kodu", type: "string" },
        { name: "category", th: "Kategori", type: "string" },
        { name: "quantity", th: "Miktar", type: "quantity", width: "15%" },
        { name: "price", th: "Birim Fiyatı", type: "editable_currency", width: "15%" },
        { name: "total_price", th: "Toplam Fiyatı", type: "total_price" },
      ],
    };
  },
  components: {
    NewAddress,
    Address,
  },
  validations: {
    defaultPayment: {
      required,
    },
    orderStatus: {
      required,
    },
    orderShipment: {
      country: {
        required,
      },
      city: {
        required,
      },
      district: {
        required,
      },
      neighborhood: {
        required,
      },
      postal_code: {
        required,
      },
      address: {
        required,
      },
    },
    orderInvoice: {
      country: {
        required,
      },
      city: {
        required,
      },
      district: {
        required,
      },
      neighborhood: {
        required,
      },
      postal_code: {
        required,
      },
      address: {
        required,
      },
    },
    orderCustomer: {
      required,
    },
    cargoCompany: {
      required,
    },
    orderProducts: {
      required,
      minLength: minLength(1),
    },
  },
  methods: {
    ...mapActions("order", [
      "create",
      "createWithOutOrder",
      "update",
      "getById",
      "load",
      "search",
      "selectCustomerAndAddress",
      "removeCustomerAndAddress",
    ]),
    ...mapMutations("order", ["selectShipmentAddress", "selectInvoiceAddress"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),

    copyWithoutLink() {
      navigator.clipboard.writeText(this.withoutUrl);
      this.$toast.info(`Sipariş linki kopyalandı.`);
    },
    updateInvoiceAddress(e) {
      if (e.target.checked) {
        const address = this.order.new.address;
        helper.copy(address.shipment, address.invoice);
      }
    },
    getOrderById() {
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            this.ready = true;
          },
        });
      } else {
        this.ready = true;
      }
    },
    validateForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    addWithOutOrder() {
      this.createWithOutOrder({
        description: this.withOutSaleOrder.description,
        price: this.withOutSaleOrder.price.unmask,
        member_id: this.orderCustomer.id,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$swal({
              title: "Kayıt Başarılı",
              text: "Kullanıcının mail adresine ödeme işlemini tamamlaması için bir bağlantı gönderildi.",
              icon: "success",
              confirmButtonText: "Tamam",
              showCancelButton: false,
            }).then((confirm) => {
              if (confirm.isConfirmed) {
                this.withoutUrl = result.data.link
              }
            });
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    saveWithOutOrder() {
      this.submit = true;
      if (this.$route.params.id) {
        //this.updateOrder();
      } else {
        this.addWithOutOrder();
      }
    },
    saveForm() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;

      if (this.$route.params.id) {
        this.updateOrder();
      } else {
        this.addOrder();
      }
    },
    addOrder() {
      this.create({
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showAlert(result, this.redirectToList);
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateOrder() {
      this.update({
        id: this.$route.params.id,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showAlert(result, this.redirectToList);
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    selectShipment(index) {
      this.selectShipmentAddress(index);
      this.show.shipment = false;
    },
    selectInvoice(index) {
      this.selectInvoiceAddress(index);
      this.show.invoice = false;
    },
    redirectToList() {
      this.$router.push("/orders");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    onAddressSave(data) {
      this.orderCustomer.contacts.push(data);
      this.show.modal = false;
    },
    getTotalPrice(data) {
      this.order.new.totalPrice = data.totalPrice;
      this.order.new.currency = data.currency;
    },
  },
  computed: {
    ...mapState(["order", "shared", "session"]),
    getTitle() {
      return this.$route.params.id ? "SİPARİŞ DÜZENLE" : "SİPARİŞ EKLE";
    },
    checkPermission() {
      return permission.check("order", "u");
    },
    defaultPayment: {
      get() {
        return this.order.new.type.payment;
      },
      set(value) {
        this.order.new.type.payment = value;
      },
    },
    orderStatus: {
      get() {
        return this.order.new.type.order;
      },
      set(value) {
        this.order.new.type.order = value;
      },
    },
    orderShipment: {
      get() {
        return this.order.new.address.shipment;
      },
      set(value) {
        this.order.new.address.shipment = value;
      },
    },
    orderInvoice: {
      get() {
        return this.order.new.address.invoice;
      },
      set(value) {
        this.order.new.address.invoice = value;
      },
    },
    orderCustomer: {
      get() {
        return this.order.new.customer;
      },
      set(value) {
        this.order.new.customer = value;
      },
    },
    sendLink: {
      get() {
        return this.order.new.send_link;
      },
      set(value) {
        this.order.new.send_link = value;
      },
    },
    description: {
      get() {
        return this.order.new.description;
      },
      set(value) {
        this.order.new.description = value;
      },
    },
    orderProducts: {
      get() {
        return this.order.new.products;
      },
      set(value) {
        this.order.new.products = value;
      },
    },
    cargoCompany: {
      get() {
        return this.order.new.cargoCompany;
      },
      set(value) {
        this.order.new.cargoCompany = value;
      },
    },
  },
  mounted() {
    this.load();
    this.getOrderById();
  },
};
</script>

<style lang="scss" scoped>
.address {
  max-height: 100px;
  resize: none;
}
.result-count {
  position: absolute;
  right: 10px;
  z-index: 1060;
  top: 7px;
}
</style>
